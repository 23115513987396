import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        redirect: '/HomePage',
        children: [
            {
                path: '/HomePage',
                name: 'HomePage',
                component: () => import('../views/homePage/index.vue'),
            },
            {
                path: '/CompanyProfile',
                name: 'CompanyProfile',
                component: () => import('../views/companyProfile/index.vue'),
            },
            {
                path: '/ProductCenter',
                name: 'ProductCenter',
                component: () => import('../views/productCenter/index.vue'),
                children: [
                    {
                        path: '/ProductCenter/huanbaoyushuikou',
                        name: 'Huanbaoyushuikou',
                        component: () => import('../views/productCenter/components/huanbaoyushuikou.vue'),
                    },
                    {
                        path: '/ProductCenter/chengshageyoujing',
                        name: 'Chengshageyoujing',
                        component: () => import('../views/productCenter/components/chengshageyoujing.vue'),
                    },
                    {
                        path: '/ProductCenter/fangshitoushuipuzhuan',
                        name: 'Fangshitoushuipuzhuan',
                        component: () => import('../views/productCenter/components/fangshitoushuipuzhuan.vue'),
                    },
                    {
                        path: '/ProductCenter/taocitoushuipuzhuan',
                        name: 'Taocitoushuipuzhuan',
                        component: () => import('../views/productCenter/components/taocitoushuipuzhuan.vue'),
                    },
                    {
                        path: '/ProductCenter/wujitoushuihunningtulumian',
                        name: 'Wujitoushuihunningtulumian',
                        component: () => import('../views/productCenter/components/wujitoushuihunningtulumian.vue'),
                    },
                    {
                        path: '/ProductCenter/paishuiluyuanshi',
                        name: 'Paishuiluyuanshi',
                        component: () => import('../views/productCenter/components/paishuiluyuanshi.vue'),
                    },
                    {
                        path: '/ProductCenter/xianxinggou',
                        name: 'Xianxinggou',
                        component: () => import('../views/productCenter/components/xianxinggou.vue'),
                    },
                    {
                        path: '/ProductCenter/hongxishipaishuixitong',
                        name: 'Hongxishipaishuixitong',
                        component: () => import('../views/productCenter/components/hongxishipaishuixitong.vue'),
                    },
                    {
                        path: '/ProductCenter/liuliangji',
                        name: 'Liuliangji',
                        component: () => import('../views/productCenter/components/liuliangji.vue'),
                    },
                    {
                        path: '/ProductCenter/leidayeweiji',
                        name: 'Leidayeweiji',
                        component: () => import('../views/productCenter/components/leidayeweiji.vue'),
                    },
                    {
                        path: '/ProductCenter/qipaoyeweiji',
                        name: 'Qipaoyeweiji',
                        component: () => import('../views/productCenter/components/qipaoyeweiji.vue'),
                    },
                    {
                        path: '/ProductCenter/yaliyeweiji',
                        name: 'Yaliyeweiji',
                        component: () => import('../views/productCenter/components/yaliyeweiji.vue'),
                    },
                    {
                        path: '/ProductCenter/yinjingcaijiyi',
                        name: 'Yinjingcaijiyi',
                        component: () => import('../views/productCenter/components/yinjingcaijiyi.vue'),
                    },
                    {
                        path: '/ProductCenter/zhihuishuiwu',
                        name: 'Zhihuishuiwu',
                        component: () => import('../views/productCenter/components/zhihuishuiwu.vue'),
                    },
                    {
                        path: '/ProductCenter/yunweiguanli',
                        name: 'Yunweiguanli',
                        component: () => import('../views/productCenter/components/yunweiguanli.vue'),
                    },
                    {
                        path: '/ProductCenter/wulianganzhi',
                        name: 'Wulianganzhi',
                        component: () => import('../views/productCenter/components/wulianganzhi.vue'),
                    },
                    {
                        path: '/ProductCenter/paishuiguanwang',
                        name: 'Paishuiguanwang',
                        component: () => import('../views/productCenter/components/paishuiguanwang.vue'),
                    },
                    {
                        path: '/ProductCenter/fanghongpailao',
                        name: 'Fanghongpailao',
                        component: () => import('../views/productCenter/components/fanghongpailao.vue'),
                    },
                    {
                        path: '/ProductCenter/shuihuanjingguanli',
                        name: 'Shuihuanjingguanli',
                        component: () => import('../views/productCenter/components/shuihuanjingguanli.vue'),
                    },
                    {
                        path: '/ProductCenter/zhihuihaimian',
                        name: 'Zhihuihaimian',
                        component: () => import('../views/productCenter/components/zhihuihaimian.vue'),
                    },
                    {
                        path: '/ProductCenter/zhihuigeishui',
                        name: 'Zhihuigeishui',
                        component: () => import('../views/productCenter/components/zhihuigeishui.vue'),
                    },
                ]
            },
            {
                path: '/CustomerCase',
                name: 'CustomerCase',
                component: () => import('../views/customerCase/index.vue'),
            },
            {
                path: '/CompanyStrength',
                name: 'CompanyStrength',
                component: () => import('../views/companyStrength/index.vue'),
            },
            {
                path: '/NewsCenter',
                name: 'NewsCenter',
                component: () => import('../views/newsCenter/index.vue'),
                children: [
                    {
                        path: '/NewsCenter/news1',
                        name: 'News1',
                        component: () => import('../views/newsCenter/components/news1.vue'),
                    },
                    {
                        path: '/NewsCenter/news2',
                        name: 'News2',
                        component: () => import('../views/newsCenter/components/news2.vue'),
                    },
                    {
                        path: '/NewsCenter/news3',
                        name: 'News3',
                        component: () => import('../views/newsCenter/components/news3.vue'),
                    },
                    {
                        path: '/NewsCenter/news4',
                        name: 'News4',
                        component: () => import('../views/newsCenter/components/news4.vue'),
                    },
                    {
                        path: '/NewsCenter/news5',
                        name: 'News5',
                        component: () => import('../views/newsCenter/components/news5.vue'),
                    },
                ]
            },
            {
                path: '/ContactUs',
                name: 'ContactUs',
                component: () => import('../views/contactUs/index.vue'),
            },
        ]
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }

});

export default router;
