<template>
    <div :class="['header', headerBlock ? 'blackColor' : 'noColor']">
        <div class="container">
            <div class="header-logo">
                <img src="../assets/img/logo.png" alt="" />
            </div>
            <div class="header-menu">
                <div class="item-menu" @click="lineTo('HomePage')">
                    <span>首页</span>
                </div>
                <div class="item-menu" @click="lineTo('CompanyProfile')">
                    <span>公司简介</span>
                </div>
                <div
                    class="item-menu"
                    @click="lineTo('ProductCenter/huanbaoyushuikou')"
                >
                    <span>产品中心</span>
                </div>
                <!-- <div class="item-menu" @click="lineTo('CustomerCase')">
                    <span>客户案例</span>
                </div> -->
                <div class="item-menu">
                    <span @click="lineTo('CompanyStrength')">公司实力</span>
                </div>
                <div class="item-menu" @click="lineTo('NewsCenter/news1')">
                    <span>新闻中心</span>
                </div>
                <div class="item-menu" @click="lineTo('ContactUs')">
                    <span>联系我们</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "nav-header",
    data() {
        return {
            headerBlock: false,
        };
    },
    mounted() {
        if (window.location.pathname == "/HomePage") {
            this.headerBlock = false;
        } else {
            this.headerBlock = true;
        }
    },
    methods: {
        lineTo(data) {
            if (data == "HomePage") {
                this.headerBlock = false;
            } else {
                this.headerBlock = true;
            }
            this.$router.push("/" + data);
        },
    },
};
</script>
<style lang="scss">
@import "./../assets/scss/base.scss";
@import "./../assets/scss/mixin.scss";
@import "./../assets/scss/config.scss";
.header {
    width: 100%;
    position: fixed;
    // background-color: #00000050;
    // background-color: rgba(153, 192, 222);
    border-bottom: 1px solid #00000010;
    color: #b0b0b0;
    z-index: 100;
    user-select: none;
    .container {
        position: relative;
        height: 100px;
        @include flex(none);
        .header-logo {
            img {
                width: 230px;
                margin-top: -10px;
            }
        }
        .header-menu {
            display: inline-block;
            width: 1000px;
            padding-left: 180px;
            .item-menu {
                height: 100px;
                display: inline-block;
                border-bottom: none;
                color: #ffffff;
                font-size: 16px;
                line-height: 100px;
                margin-right: 70px;
                box-sizing: border-box !important;
                -moz-box-sizing: border-box !important;
                -ms-box-sizing: border-box !important;
                -webkit-box-sizing: border-box !important;
                -o-box-sizing: border-box !important;
                span {
                    cursor: pointer;
                }
                &:hover {
                    // color: $colorA;
                    border-bottom: 2px solid #fff;
                    .children {
                        height: 220px;
                        opacity: 1;
                    }
                }
                .children {
                    position: absolute;
                    top: 112px;
                    left: 0;
                    width: 1226px;
                    height: 0;
                    opacity: 0;
                    overflow: hidden;
                    border-top: 1px solid #e5e5e5;
                    box-shadow: 0px 7px 6px 0px rgba(0, 0, 0, 0.11);
                    z-index: 10;
                    transition: all 0.5s;
                    background-color: #ffffff;
                    .product {
                        position: relative;
                        float: left;
                        width: 16.6%;
                        height: 220px;
                        font-size: 12px;
                        line-height: 12px;
                        text-align: center;
                        a {
                            display: inline-block;
                        }
                        img {
                            width: auto;
                            height: 111px;
                            margin-top: 26px;
                        }
                        .pro-img {
                            height: 137px;
                        }
                        .pro-name {
                            font-weight: bold;
                            margin-top: 19px;
                            margin-bottom: 8px;
                            color: $colorB;
                        }
                        .pro-price {
                            color: $colorA;
                        }
                        &:before {
                            content: " ";
                            position: absolute;
                            top: 28px;
                            right: 0;
                            border-left: 1px solid $colorF;
                            height: 100px;
                            width: 1px;
                        }
                        &:last-child:before {
                            display: none;
                        }
                    }
                }
            }
        }
        .header-search {
            width: 319px;
            .wrapper {
                height: 50px;
                border: 1px solid #e0e0e0;
                display: flex;
                align-items: center;
                input {
                    border: none;
                    box-sizing: border-box;
                    border-right: 1px solid #e0e0e0;
                    width: 264px;
                    height: 50px;
                    padding-left: 14px;
                }
                a {
                    @include bgImg(18px, 18px, "/imgs/icon-search.png");
                    margin-left: 17px;
                }
            }
        }
    }
}
.blackColor {
    background-color: #000;
}
.noColor {
    background-color: #00000010;
}
</style>